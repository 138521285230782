import Navbar from "../components/Navbar"
import Generalsearchask from "../components/Generalsearchask";    
const Generalsearch = ()=>{
    console.log("Booking")
    return(
        <>
        <Navbar/>
        <div className="w-full overflow-y-auto"> {/* Make this section scrollable */}
                    < Generalsearchask/>
                </div>

        </>
    )
}
export default Generalsearch