import { useNavigate } from "react-router-dom";
import { useState } from "react";

const HistoryComp = (props) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className="bg-gradient-to-r from-blue-50 to-purple-100 shadow-lg rounded-lg p-4 mb-4 transition-all duration-300 hover:shadow-2xl">
      <div className="text-lg font-semibold text-gray-800 mb-2">
        {props.heading && typeof props.heading === "string" ? (
          <>
            {isExpanded
              ? props.heading
              : props.heading.length > 200
              ? `${props.heading.slice(0, 200)}...`
              : props.heading}
            {props.heading.length > 200 && (
              <button
                className="text-blue-500 hover:text-blue-600 transition ml-2 text-sm"
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            )}
          </>
        ) : (
          <span className="text-red-500">No User Input available</span>
        )}
      </div>
      <div className="text-gray-700 leading-relaxed mb-2">
        {props.desc && typeof props.desc === "string" ? (
          <>
            {isExpanded
              ? props.desc
              : props.desc.length > 300
              ? `${props.desc.slice(0, 300)}...`
              : props.desc}
            {props.desc.length > 300 && (
              <button
                className="text-indigo-500 hover:text-indigo-600 transition ml-2 text-sm"
                onClick={handleToggle}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            )}
          </>
        ) : (
          <span className="text-red-500">No Bot response available</span>
        )}
      </div>

      <button
        onClick={() => navigate("/details")}
        className="mt-2 bg-gradient-to-br from-indigo-500 to-blue-600 text-white px-4 py-2 rounded-lg w-full hover:scale-105 transition-transform"
      >
        View Details
      </button>
    </div>
  );
};

export default HistoryComp;
