import Navbar from "../components/Navbar"
import Asklabbook from "../components/Asklabbook";
import languageContext from "../context/language/languageContext";
import { useContext, useEffect, useState } from "react";    
const Booking = ()=>{
    console.log("Booking")
    const { labbook,setlabbook } = useContext(languageContext);
    useEffect(()=>{
        setlabbook("Book My Lab Appointment")
    }, [])
    return(
        <>
        <Navbar/>
        <div className="w-full overflow-y-auto"> {/* Make this section scrollable */}
                    < Asklabbook labbook={labbook} setlabbook={setlabbook}/>
                </div>

        </>
    )
}
export default Booking