import Navbar from "../components/Navbar"
import Askchecklist from "../components/Askchecklist";
import languageContext from "../context/language/languageContext";
import { useContext, useEffect, useState } from "react";    
const Checklist = ()=>{
    const { checklist, setchecklist } = useContext(languageContext);
    useEffect(()=>{
        setchecklist("Checklist for Next Appointment")
    }, [])
    return(
        <>
        <Navbar/>
        <div className="w-full overflow-y-auto"> {/* Make this section scrollable */}
                    <Askchecklist checklist={checklist} setchecklist={setchecklist}/>
                </div>

        </>
    )
}
export default Checklist