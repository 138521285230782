import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/aica.svg";
import data from "../language.json";
import languageContext from "../context/language/languageContext";

const FrontPage = () => {
  const navigate = useNavigate();
  const { setLang } = useContext(languageContext);
  const currentPagePath = window.location.pathname;

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleLanguageChange = (event) => {
    const selectedLanguageIndex = event.target.value;
    console.log(selectedLanguageIndex, 
        "selectedLanguageIndex")
    if (selectedLanguageIndex !== "") {
      setLang(selectedLanguageIndex);
      const loginBool = localStorage.getItem("login");
      console.log(loginBool, "loginBool")
      if (loginBool === "true") {
        navigate("/ask", { state: { url: currentPagePath } });
      } else {
        navigate("/login", { state: { url: currentPagePath } });
      }
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-gradient-to-br from-teal-500 via-green-400 to-blue-300 p-6">
      {/* Logo Section */}
      <div className="flex flex-col justify-center items-center mb-8 animate-fadeIn">
        <img
          className="w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4 drop-shadow-lg"
          src={logo}
          alt="frontPagePic"
        />
        <h1 className="text-white font-bold text-center text-4xl sm:text-5xl md:text-6xl">
          Hi! Welcome to AICA
        </h1>
      </div>

      {/* Subtitle Section */}
      <p className="text-white text-lg sm:text-xl md:text-2xl mb-6 font-medium text-center">
        Please choose your preferred language
      </p>

      {/* Language Selector */}
      <div className="w-full max-w-md mb-6 relative">
        <select
          onChange={handleLanguageChange}
          className="bg-white border-2 border-[#A6C7E3] w-full py-3 px-4 rounded-lg text-gray-800 shadow-md focus:outline-none focus:ring-2 focus:ring-[#A8D4E7] transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg cursor-pointer"
        >
          <option value="" >Select Language</option>
          {data.map((language) => (
        
            <option key={language.index} value={language.index}>
                {console.log(language.name, language.index)}
              {capitalizeFirstLetter(language.name)}
            </option>
          ))}
        </select>
      </div>

      {/* Optional Animated Background Circles */}
      <div className="absolute w-[450px] h-[450px] bg-green-300 opacity-30 rounded-full top-[10%] left-[5%] animate-pulse"></div>
      <div className="absolute w-[300px] h-[300px] bg-teal-300 opacity-40 rounded-full bottom-[10%] right-[5%] animate-pulse"></div>

      {/* Footer */}
      <div className="text-white text-sm mt-6">
        Powered by <span className="font-semibold">AICA</span> | Your Smart Health Assistant
      </div>
    </div>
  );
};

export default FrontPage;
