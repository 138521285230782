import HistoryComp from "./History";
import languageContext from "../context/language/languageContext";
import { useContext, useEffect, useState } from "react";
import axios from "axios";

const PrevQues = () => {
  // Accessing chats from languageContext
  const { chats, setChats, dataApi, setAlert, setMessage } = useContext(languageContext);
  const { lang, setSessionTimeout } = useContext(languageContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    gettingResponse();
  }, [dataApi]);

  const gettingResponse = async () => {
    try {
      let headersList = {
        Accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      let response = await axios.post(
        "https://www.careplus.gopillz.com/chat_history",
        null,
        { headers: headersList }
      );
      setChats(response.data.chat_history);
    } catch (error) {
      if (error.response && error.response.data.detail === "Token has expired") {
        setSessionTimeout(true);
      } else {
        console.error("Error Details:", error.message);
        setAlert(true);
        setMessage("Please try again later, something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="relative flex justify-center items-start h-full overflow-y-auto ">
      <div className="w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
  {/* Chat History Header */}
  <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center border-b-2 pb-2">
    Chat History
  </h2>

        {isLoading ? (
          <div className="space-y-4 animate-pulse">
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className="bg-gray-300 h-20 rounded-lg w-full"
              ></div>
            ))}
          </div>
        ) : chats && chats.length > 0 ? (
          <div className="space-y-4">
            {chats && chats.map((chat, index) => (
                    <HistoryComp
                        key={index}
                        heading={chat.user_question} 
                        desc={chat.bot_response}
                    />
                ))}  
             
          </div>
        ) : (
          <div className="text-center text-gray-600 mt-12">
            <p className="text-lg">No chat history found. Start asking questions!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrevQues;


