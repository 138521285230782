import Navbar from "../components/Navbar";
import data from "../language.json";
import React, { useContext, useState } from "react";
import languageContext from "../context/language/languageContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Profile = () => {
    const { lang } = useContext(languageContext);
    const navigate = useNavigate();
    const [details, setDetails] = useState({
        patient_name: '',
        abha_id: '',
        doctor_name: '',
        doctor_number: '',
        hospital_name: '',
        hospital_number: '',
        caregiver_number: '',
        caregiver_name: '',
        relation_of_patient: '',
    });

    const handleChange = (e, field) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            [field]: e.target.value
        }));
    };

    const submitProfile = async () => {
        try {
            const headerList = {
                "Accept": "*/*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            };
            await axios.post(
                "https://www.careplus.gopillz.com/sigup_details",
                { data: details },
                { headers: headerList }
            );
            navigate("/ask");
        } catch (error) {
            console.error("Error saving the details: ", error);
            alert("Could not save your data, please try again later");
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <Navbar />
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-8">
                <h2 className="text-2xl font-bold text-center mb-6">{data[lang]["Profile Heading"]}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {data[lang]["profile"].map((item, index) => (
                        <div key={index} className="flex flex-col">
                            <label className="text-gray-700 font-semibold">{item.name}</label>
                            <input 
                                type="text" 
                                required
                                className="mt-1 px-4 py-2 border rounded-lg bg-gray-50 focus:ring-2 focus:ring-blue-400"
                                value={details[item.value]} 
                                onChange={(e) => handleChange(e, item.value)} 
                            />
                        </div>
                    ))}
                </div>
                
                <h3 className="text-xl font-semibold mt-8 mb-4">{data[lang]["Emergency Heading"]}</h3>
                {data[lang]["Emergency"].map((emergency, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-lg shadow-sm mb-6">
                        <h4 className="text-lg font-semibold mb-4">{emergency.name}</h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {emergency.fields.map((field, idx) => (
                                <div key={idx} className="flex flex-col">
                                    <label className="text-gray-700 font-semibold">{field.name}</label>
                                    <input 
                                        type="text" 
                                        required
                                        className="mt-1 px-4 py-2 border rounded-lg bg-gray-50 focus:ring-2 focus:ring-blue-400"
                                        value={details[field.value]} 
                                        onChange={(e) => handleChange(e, field.value)} 
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                
                <div className="text-center mt-6">
                    <button 
                        type='button' 
                        className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg shadow-md' 
                        onClick={submitProfile}
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Profile;
