import Navbar from "../components/Navbar"
import Askbuymedicine from "../components/Askbuymedicine";
import languageContext from "../context/language/languageContext";
import { useContext, useEffect, useState } from "react";    
const Booking = ()=>{
    console.log("Booking")
    const { buymedicine,setbuymedicine } = useContext(languageContext);
    useEffect(()=>{
        setbuymedicine("buy new medicine")
    }, [])
    return(
        <>
        <Navbar/>
        <div className="w-full overflow-y-auto"> {/* Make this section scrollable */}
                    < Askbuymedicine buymedicine={buymedicine} setbuymedicine={setbuymedicine}/>
                </div>

        </>
    )
}
export default Booking