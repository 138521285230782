import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import data from "../language.json";
import languageContext from "../context/language/languageContext";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { lang, phone, setPhone } = useContext(languageContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (phone.length !== 10 || isNaN(phone)) {
      alert("Please enter a valid 10-digit number.");
      return;
    }

    const headerList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    try {
      setLoader(true);
      const response = await axios.post(
        "https://www.careplus.gopillz.com/send_otp_al_chatbot",
        JSON.stringify({ phone }),
        { headers: headerList }
      );
      console.log(response.data.msg);
      navigate("/otp", { state: { from: "login" } });
      setLoader(false);
    } catch (error) {
      console.error("OTP cannot be sent, please try again later: ", error);
      alert("Cannot login. Please try again later.");
      setLoader(false);
    }
  };

  return (
    <div className="bg-gradient-to-br from-teal-500 via-green-400 to-blue-300 min-h-screen flex justify-center items-center relative overflow-hidden">
      {/* Main Container */}
      <div className="w-full max-w-md bg-white rounded-lg shadow-xl px-8 py-10 relative z-10 animate-fadeIn">
        {/* Progress Circles */}
        <div className="flex justify-center space-x-3 mb-8">
          <div className="bg-teal-500 w-4 h-4 rounded-full transition-all duration-300"></div>
          <div className="bg-gray-300 w-4 h-4 rounded-full hover:bg-teal-400 cursor-pointer transition-all duration-300"></div>
          <div className="bg-gray-300 w-4 h-4 rounded-full hover:bg-teal-400 cursor-pointer transition-all duration-300"></div>
        </div>

        {/* Login Heading */}
        <h1 className="text-3xl sm:text-4xl text-teal-600 font-bold mb-4">
          {data[lang]["auth"][1]}
        </h1>
        <p className="text-gray-600 mb-6">Enter your mobile number to login</p>

        {/* Login Form */}
        <form
          onSubmit={handleLogin}
          className="flex flex-col items-center space-y-4"
        >
          <input
            type="text"
            required
            maxLength="10"
            pattern="[0-9]{10}"
            className="w-full px-4 py-2 rounded-lg bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent text-center text-gray-800 transition-all duration-300 hover:scale-105"
            placeholder="Enter 10-digit mobile number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <button
            type="submit"
            className={`w-full bg-teal-600 text-white py-2 px-4 rounded-lg font-semibold transition-all duration-300 ease-in-out ${
              loader
                ? "opacity-70 cursor-not-allowed"
                : "hover:bg-teal-700 transform hover:scale-105"
            }`}
            disabled={loader}
          >
            {loader ? (
              <div className="loader w-5 h-5 border-2 border-t-white border-b-white border-l-transparent border-r-transparent animate-spin rounded-full mx-auto"></div>
            ) : (
              "Proceed"
            )}
          </button>
        </form>

        {/* Signup Link Section */}
        <div className="mt-6 text-gray-700 text-sm">
          Don't have an account?{" "}
          <Link to="/signup" className="text-teal-500 hover:underline">
            Sign up here
          </Link>
        </div>
      </div>

      {/* Background Circles */}
      <div className="absolute w-[450px] h-[450px] bg-green-300 opacity-40 rounded-full top-12 left-10 animate-pulse"></div>
      <div className="absolute w-[350px] h-[350px] bg-teal-300 opacity-30 rounded-full bottom-10 right-10 animate-pulse"></div>
    </div>
  );
};

export default Login;
