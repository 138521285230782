import React, { useContext, useState, useRef, useEffect } from "react";
import data from "../language.json";
import languageContext from "../context/language/languageContext";
import Question from "./Question";
import Answer from "./Answer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  FaArrowCircleUp,
  FaMicrophone,
  FaStop,
  FaPaperPlane,
  FaTimes,
} from "react-icons/fa";

const Ask = ({ bookappointment, setbookappointment }) => {
  const {
    lang,
    setSessionTimeout,
    dataApi,
    setData,
    setAlert,
    setMessage,
    isRightPanel,
    setIsRightPanel,
  } = useContext(languageContext);
    const mediaRecorderRef = useRef(null);
  const audioRef = useRef(null);
  const inputRef = useRef(null);
  const [visible, setVisible] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");

  const [interactions, setInteractions] = useState([]); // Store questions and answers
  const [session, setsession] = useState({});
  const [input, setInput] = useState("");
  const mainBoxRef = useRef(null);
  const navigate = useNavigate();


  const scrollToBottom = () => {
        if (mainBoxRef.current) {
          mainBoxRef.current.scrollTo({
            top: mainBoxRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      };


  const sendAudio = async () => {
        if (audioBlob) {
          const formData = new FormData();
          formData.append("language_type", data[lang]["name"]);
          formData.append("file", audioBlob, "audio.mp3");
    
          try {
            const response = await axios.post(
              "https://www.careplus.gopillz.com/voice_to_text",
              formData,
              { headers: { "Content-Type": "multipart/form-data" } }
            );
            const transcribedText = response.data.text.text;
            askQuestion(transcribedText, audioBlob);
          } catch (error) {
            setAlert(true);
            setMessage("Failed to process the audio. Please try again.");
          }
        }
      };


    const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.start();
    setIsRecording(true);

    const audioChunks = [];
    mediaRecorder.addEventListener("dataavailable", (event) => {
      if (event.data.size > 0) {
        audioChunks.push(event.data);
      }
    });

    mediaRecorder.addEventListener("stop", () => {
      const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
      setAudioBlob(audioBlob);
      setAudioUrl(URL.createObjectURL(audioBlob));
    });
  };

  // Fetch API Response
  const gettingResponse = async (text) => {
    let headersList = {
      Accept: "*/*",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({
      question: text,
      session: session,
      lang: data[lang]["name"],
    });

    try { 
      let response = await axios.post(
        "https://www.careplus.gopillz.com/al_voice_chat_webside",
        bodyContent,
        { headers: headersList } 
      );
      if (response.data.ans !== "") {
        setInteractions((prev) => [
          ...prev,
          { type: "answer", text: response.data.ans, audio: null },
        ]);
        setData((prevData) => [
          ...prevData,
          { type: "answer", text: response.data.ans },
        ]);
        setsession(response.data.session);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data.detail === "Token has expired"
      ) {
        setSessionTimeout(true);
      } else {
        console.error("Error Details:", error.message);
        setAlert(true);
        setMessage("Please try again later, something went wrong");
      }
    }
  };

  // Ask Question and Handle Input
  const askQuestion = (text) => {
    if (text !== "") {
      setData((prevData) => [...prevData, { type: "question", text: text }]);
      setInteractions((prev) => [
        ...prev,
        { type: "question", text: text, audio: null },
      ]);
      gettingResponse(text);
      setInput("");
    }
  };

  // Handle Input Change
  const handleText = (e) => {
    setInput(e.target.value);
  };

  // Handle Enter Key to Send Message
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      askQuestion(input);
    }
  };

  const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
      };

  // Scroll to Bottom After Interaction
  useEffect(() => {
    if (mainBoxRef.current) {
      mainBoxRef.current.scrollTop = mainBoxRef.current.scrollHeight;
    }
  }, [interactions]);

  return (
    <>
      <div className="flex flex-col lg:flex-row h-screen w-full bg-gray-100">
        {/* Main Chat Section */}
        <div className="flex flex-col w-full lg:w-8/12 mx-auto p-4 bg-white shadow-lg rounded-lg h-[90vh] relative">
          {/* Chat Box */}
          <div
            className="flex-grow overflow-y-auto px-4 py-2 space-y-4 pb-20" // Added padding-bottom to prevent overlap
            ref={mainBoxRef}
          >
            {interactions.map((interaction, index) =>
              interaction.type === "question" ? (
                <div key={index} className="mb-4">
                  <Question text={interaction.text} />
                </div>
              ) : (
                <Answer key={index} text={interaction.text} />
              )
            )}
            {visible && (
        <div className="mx-2 mt-10 p-4 bg-gray-200 rounded-lg shadow-md text-gray-800 text-center font-semibold">
          {data[lang]["Instructions"]}
        </div>
      )}
          </div>

          {/* Input Section */}
          <div className="bg-gray-100 absolute bottom-0 left-0 right-0 px-4 py-3 flex justify-between items-center space-x-2 rounded-b-lg shadow-inner">
          <input
          ref={inputRef}
          placeholder="Ask your question..."
          className="flex-1 px-4 py-2 rounded-md focus:outline-none border border-gray-300 w-full sm:w-auto"
          onChange={handleText}
          onKeyDown={(e) => e.key === "Enter" && askQuestion(input)}
          value={input}
        />
        {input && (
          <button
            onClick={() => setInput("")}
            className="text-red-400 hover:text-red-600 transition"
          >
            <FaTimes size="1.5rem" />
          </button>
        )}
        <button
          onClick={() => askQuestion(input)}
          className="bg-purple-500 text-white rounded-lg px-4 py-2 hover:scale-105 transition-transform"
        >
          <FaPaperPlane size="1.2rem" />
        </button>

        {/* Audio Recording Buttons */}
        {isRecording ? (
          <button
            onClick={stopRecording}
            className="bg-red-500 text-white rounded-lg px-4 py-2 animate-pulse hover:scale-105 transition-transform"
          >
            <FaStop size="1.5rem" />
          </button>
        ) : (
          <button
            onClick={startRecording}
            className="bg-green-500 text-white rounded-lg px-4 py-2 hover:scale-105 transition-transform"
          >
            <FaMicrophone size="1.5rem" />
          </button>
        )}

        {/* Send Audio Button */}
        {audioBlob && (
          <button
            onClick={sendAudio}
            className="bg-indigo-500 text-white rounded-lg px-4 py-2 hover:scale-105 transition-transform"
          >
            <FaPaperPlane size="1.2rem" />
          </button>
        )}

        {/* Scroll to Bottom Button */}
        <button
          onClick={scrollToBottom}
          className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:scale-105 transition-transform"
        >
          <FaArrowCircleUp size="1.5rem" />
        </button>
          </div>
        </div>

        {/* Symptom Tracker Sidebar */}
        <div className="w-full lg:w-4/12 px-4 py-4 bg-gray-50 shadow-inner border-t lg:border-l border-gray-200 overflow-y-auto h-[90vh] rounded-lg">
          <h3 className="text-xl font-semibold text-gray-700 mb-4 text-center">
            Symptom Tracker
          </h3>

          <div className="grid grid-cols-2 gap-4">
            {data[lang]["Symptoms"].map((text, index) => (
              <button
                key={index}
                className="flex items-center justify-center space-x-2 bg-white border border-gray-300 shadow-sm hover:shadow-md hover:bg-blue-50 text-gray-700 font-medium px-4 py-3 rounded-lg transition duration-200 ease-in-out"
                onClick={() => askQuestion(text)}
              >
                <span className="text-sm">{text}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Ask;
