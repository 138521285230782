import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import Signup from "./pages/SignupPage";
import OtpVerify from "./pages/OtpPage";
import Verify from "./components/Verify";
import LanguageState from "./context/language/LanguageState";
import Profile from "./pages/ProfilePage";
import QuestionPage from "./pages/QuestionPage";
import SessionTimeoutPopup from "./components/Session";
import Alert from "./components/Alert";
import Checklist from "./pages/Checklist";
import Booking from "./pages/Booking";
import LabBook from "./pages/LabBooking"
import Buymedicine from "./pages/Buymedicine"
import Carebuddy from "./pages/Carebuddy"
import Reminderbook from "./pages/Reminderbooking"
import Generalsearch from "./pages/Generalsearch";
function App() {
  return (
    <div className="App" >
      <BrowserRouter>
      <LanguageState>
        <Alert/>
        <SessionTimeoutPopup/>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route
            exact
            path="/otp"
            element={
              <Verify>
                <OtpVerify />
              </Verify>
            }
          />
          
          <Route exact path="/account" element={<Profile/>}/>
          <Route exact path="/ask/:id" element={<QuestionPage/>}></Route>
          <Route exact path="/ask" element={<QuestionPage/>}></Route>
          <Route exact path="/checklist" element={<Checklist/>}></Route>
          <Route exact path="/booking" element={<Booking/>}></Route>
          <Route exact path="/book_appointment" element={<Booking/>}></Route>
          <Route exact path="/lab_book" element={<LabBook/>}></Route>
          <Route exact path="/buy_medicine" element={<Buymedicine/>}></Route>
          <Route exact path="/reminder" element={<Reminderbook/>}></Route>
          <Route exact path="/care_buddy" element={<Carebuddy/>}></Route>
          <Route exact path="/checklist" element={<Carebuddy/>}></Route>
          <Route exact path="/general_serach" element={<Generalsearch/>}></Route>
        </Routes>
        </LanguageState>
      </BrowserRouter>
    </div>
  );
}

export default App;
