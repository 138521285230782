import { FaArrowLeft } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiLogoutCircleRLine, RiChatHistoryLine } from "react-icons/ri";
import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import languageContext from "../context/language/languageContext";

const Navbar = () => {
  const {
    setChats,
    setbookappointment,
    checklist,
    setchecklist,
    bookappointment,
    setIsLeftPanel,
    isLeftPanel,
    labbook,
    labsetbook,
    sessionTimeout,
    isRightPanel,
    setIsRightPanel,
  } = useContext(languageContext);

  const navigate = useNavigate();
  const location = useLocation();
  const url = location.state?.url || "/";

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    navigate("/");
  };

  const handleBackArrowPress = () => {
    navigate(url);
  };

  const handlehome = () => {
    navigate("/ask");
  };

  const handleprofile=()=>{
    navigate("/account");

  }

  const handleBookAppointment = () => {
    navigate("/book_appointment");
  };

  const handleChecklist = () => {
    navigate("/checklist");
  };

  const handlelabAppointment = () => {
    navigate("/lab_book");
  };

  const handlereminderAppointment = () => {
    navigate("/reminder");
  };

  const handleMedicine = () => {
    navigate("/buy_medicine");
  };

  const handleGeneralAssistant = () => {
    navigate("/general_serach");
  };

  return (
    <div className="bg-gradient-to-br from-teal-500 via-green-400 to-blue-300 w-full h-[70px] md:h-[80px] flex items-center justify-between px-6 shadow-lg">
      {/* Left Section: Back & Chat History */}
      <div className="flex items-center space-x-4">
        {/* Back Button */}
        <div
          className="w-[40px] h-[40px] md:w-[44px] md:h-[44px] bg-gray-700 text-white rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-600 transition duration-300"
          onClick={handleBackArrowPress}
        >
          <FaArrowLeft size="1.4rem" />
        </div>

        {/* Chat History Button */}
        {localStorage.getItem("login") && (
          <div
          className="md:hidden w-[40px] h-[40px] md:w-[44px] md:h-[44px] bg-gray-700 text-white rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-600 transition duration-300"
          onClick={() => {
            setIsRightPanel(false);
            setIsLeftPanel(!isLeftPanel);
          }}
        >
          <RiChatHistoryLine size="1.4rem" className="block" />
        </div>
        
        )}
      </div>

      {/* Center Section: Dynamic Buttons */}
      <div className="hidden md:flex items-center space-x-4">
        {location.pathname.includes("/ask") && (
          <>
            <button
              onClick={handleBookAppointment}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
            >
              Book My Next Appointment
            </button>

            <button
              onClick={handlelabAppointment}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
            >
              Book My Lab Appointment
            </button>

            <button
              onClick={handlereminderAppointment}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
            >
              Book My Reminder
            </button>

            <button
              onClick={handleMedicine}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
            >
              Buy Medicine
            </button>

            <button
              onClick={handleGeneralAssistant}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
            >
              General Assistant
            </button>


            <button
            onClick={handleprofile}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
          >
            Profile
          </button>
            {/* <button
              onClick={handleChecklist}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
            >
              Checklist
            </button> */}
          </>
        )}

        {[
          "/book_appointment",
          "/lab_book",
          "/reminder",
          "/buy_medicine",
          "/general_serach",
          "/checklist",
          "/account"
        ].some((path) => location.pathname.includes(path)) && (
          <>
          <button
            onClick={handlehome}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
          >
            Home
          </button>
          
          </>
          
          
        )}
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden flex items-center space-x-4">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="w-[40px] h-[40px] bg-gray-700 text-white rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-600 transition duration-300"
        >
          <GiHamburgerMenu size="1.5rem" />
        </button>
      </div>

      {/* Right Section: Logout & Hamburger */}
      <div className="hidden md:flex items-center space-x-4">
        {/* Logout Button */}
        <div
          className="w-[44px] h-[44px] bg-red-600 text-white rounded-full flex justify-center items-center cursor-pointer hover:bg-red-700 transition duration-300"
          onClick={logout}
        >
          <RiLogoutCircleRLine size="1.4rem" />
        </div>

        {/* Hamburger Menu Button */}
        <div className="md:hidden flex items-center space-x-4">
  <button
    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
    className="w-[40px] h-[40px] bg-gray-700 text-white rounded-full flex justify-center items-center cursor-pointer hover:bg-blue-600 transition duration-300"
  >
    <GiHamburgerMenu size="1.5rem" />
  </button>
</div>

      </div>

      {/* Mobile Dropdown Menu */}
      {isMobileMenuOpen && (
        <div className="absolute top-16 right-6 w-48 bg-white shadow-lg rounded-lg z-50 flex flex-col space-y-2 p-4">
          {location.pathname.includes("/ask") && (
            <>
              <button
                onClick={handleBookAppointment}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
              >
                Book Appointment
              </button>
              <button
                onClick={handlelabAppointment}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
              >
               Book My Lab Appointment
              </button>
              <button
                onClick={handlereminderAppointment}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
              >
                Book My Reminder
              </button>
              <button
                onClick={handleMedicine}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
              >
                Buy Medicine
              </button>

              <button
                onClick={handleGeneralAssistant}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
              >
                General Assistant
              </button>
              <button
                onClick={handleprofile}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
              >
                Profile
              </button>
            </>
          )}
          {[
            "/book_appointment",
            "/lab_book",
            "/reminder",
            "/buy_medicine",
            "/general_serach",
            "/checklist",
            "/account"
          ].some((path) => location.pathname.includes(path)) && (
            <>
            <button
              onClick={handlehome}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-blue-700 transition duration-300"
            >
              Home
            </button>
            </>
            
          )}
          <button
            onClick={logout}
            className="bg-red-600 text-white px-4 py-2 rounded-lg text-sm font-semibold shadow-md hover:bg-red-700 transition duration-300"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
