import React, { useContext,useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import languageContext from "../context/language/languageContext";
import data from "../language.json";

function OtpVerify() {
  const { lang, phone, setAlert, setMessage, setPhone } =
    useContext(languageContext);
  const [Otp, setOtp] = useState(new Array(4).fill("")); // For OTP input
  const [counter, setCounter] = useState(120); // Timer state (2 minutes)
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const location = useLocation();
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const { from } = location.state || {};

  // Handle OTP change in inputs
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...Otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  // Timer countdown for resending OTP
  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter]);

  // Convert seconds to MM:SS format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !Otp[index]) {
        const newotp = [...Otp]
        newotp[index - 1] = ""
        inputRefs.current[index - 1].focus()
        setOtp(newotp)


    }
}

const verifyOtp = async () => {
    const otpValue = Otp.join("");

    if (otpValue.length !== 4) {
      setAlert(true);
      setMessage("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      setVerifyingOtp(true);

      const response = await axios.post(
        "https://www.careplus.gopillz.com/login_al_chatbot",
        {
          phone: phone,
          otp: otpValue,
        },
        {
          headers: {
            "Accept": "*/*",
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response)

      if (response.data.access_token ||response.status==200) {
        console.log(from, "form")
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("login", true);
        setPhone(""); // Reset phone state
        if (from === "login" || from ==="signup") {
          navigate("/ask");
        } else {
          navigate("/login");
        }
      }
      if (response.status === 401) {
        setAlert(true);
        setMessage(response.data.msg || "OTP verification failed. Please try again.");

      }
      
    } catch (error) {
      console.log(error)
      if (error.status === 401) {
        setAlert(true);
        setMessage(error.response.data.msg || "OTP verification failed. Please try again.");

      } else {
        setAlert(true);
        setMessage(error.response.data.msg || "OTP verification failed. Please try again.");

      }
      // console.error("Error during OTP verification:", error.status);
      // setAlert(true);
      // setMessage("An error occurred. Please try again.");
    } finally {
      setVerifyingOtp(false);
    }
  };


  const resendotp=async ()=>{
    setCounter(120)
    try{
        const headerList = {
            Accept: "*/*",
            "Content-Type": "application/json",
          };
        const response = await axios.post(
            "https://www.careplus.gopillz.com/send_otp_al_chatbot",
            JSON.stringify({ phone }),
            { headers: headerList }
          );
          console.log(response.data.msg);
    }
    catch (error) {
        console.error("OTP cannot be sent, please try again later: ", error);
        alert("Cannot login. Please try again later.")

  }
}

  // API Request for OTP verification

  return (
    <div className="bg-gradient-to-br from-teal-500 via-green-400 to-blue-300 min-h-screen flex flex-col items-center justify-center relative">
      {/* Main Container */}
      <div className="flex flex-col items-center text-center bg-white p-8 rounded-2xl shadow-2xl w-full max-w-md animate-fadeIn relative z-10">
        <h2 className="text-3xl text-teal-600 font-bold mb-4">
          {data[lang]["auth"][2]}
        </h2>
        <p className="text-lg text-gray-600 mb-6">
          Enter the code sent to your registered phone number
        </p>

        {/* OTP Input Fields */}
        <div className="flex justify-between mb-6 w-11/12 sm:w-9/12 lg:w-7/12 space-x-2">
          {Otp.map((data, index) => (
            <input
            type="text"
            id={`otp-${index}`}
            key={index}
            name={`otp-${index}`}
            maxLength={1}
            value={data}
            autoFocus={index === 0}
            ref={(ref) => { inputRefs.current[index] = ref }}
            onKeyDown={(e) => handleKeyDown(index, e)}
              className="w-10 h-10 border border-gray-300 rounded-lg text-center text-lg focus:ring-2 focus:ring-sky-500 focus:border-sky-500"
              onChange={(e) => handleChange(e.target, index)}
              required
            />
          ))}
        </div>

        {/* OTP Verification Button */}
        <button
          onClick={verifyOtp}
          className={`w-full bg-teal-500 text-white py-2 px-4 rounded-lg mb-4 text-lg font-semibold transition duration-300 ${
            verifyingOtp
              ? "opacity-70 cursor-not-allowed"
              : "hover:bg-teal-600 hover:scale-105"
          }`}
          disabled={verifyingOtp}
        >
          {verifyingOtp ? (
            <div className="w-6 h-6 border-2 border-white border-t-transparent border-r-transparent rounded-full animate-spin mx-auto"></div>
          ) : (
            "Verify OTP"
          )}
        </button>

        {/* Timer and Resend Link */}
        <div className="text-sm text-gray-600">
          {counter > 0 ? (
            <div className="mb-2">
              Resend OTP in{" "}
              <span className="text-teal-500 font-bold">{formatTime(counter)}</span>
            </div>
          ) : (
            <button
            onClick={resendotp}
              className="text-teal-500 hover:underline focus:outline-none"
            >
              Resend OTP?
            </button>
          )}
        </div>
      </div>

      {/* Animated Circles */}
      <div className="absolute w-[450px] h-[450px] bg-teal-300 opacity-40 rounded-full top-16 left-8 animate-pulse"></div>
      <div className="absolute w-[300px] h-[300px] bg-green-400 opacity-30 rounded-full bottom-10 right-10 animate-pulse"></div>
    </div>
  );
}

export default OtpVerify;
